<template>
  <v-dialog v-model="open" width="500" :fullscreen="xsOnly">
    <v-card>
      <v-card-title class="headline">{{transactionId ? $t('transactions.success.top_up_balance') : $t('accounts.actions.top_up_balance')}}</v-card-title>
      <v-card-text>
        <template v-if="!transactionId">
          <v-layout column class="mb-6">
            <!-- AMOUNT -->
            <v-layout>
              <v-flex xs12 sm8>
                <price-input v-if="open"
                  :disabled="loading.complete"
                  @input="(v) => amount = v"
                  :currencyId="currencyId"
                  class="mb-2"
                  autofocus
                />
              </v-flex>
            </v-layout>
            <!-- CURRENCY -->
            <span class="caption">{{ $t('fields.currency') }}</span>
            <v-btn-toggle
              background-color="transparent"
              v-model="currencyId"
              color="primary"
              borderless
              mandatory
              class="mb-2"
            >
              <v-btn v-for="curr in currencies" :key="curr.id" :value="curr.id" :disabled="loading.complete">
                {{curr.name}}
              </v-btn>
            </v-btn-toggle>
            <!-- (MXN AMOUNT) -->
            <v-layout v-if="foreignCurrency && amount" class="headline text-right">
              <v-flex xs12 sm8>
                <span>{{formatAmount(amount * (currencyId === foreignCurrency.id ? foreignCurrencyExchangeRate : 1))}} {{localCurrency.name}}</span>
              </v-flex>
            </v-layout>
            <!-- PAYMENT METHODS -->
            <span class="caption mb-2">{{ $t('fields.payment_method') }}</span>
            <payment-method-toggle
              :disabled="loading.complete"
              :value="paymentMethodId"
              @change="v => { paymentMethodId = v }"
            />
          </v-layout>
        </template>
        <!-- (CHANGE CALCULATOR) -->
        <price-input v-if="paymentMethodId === cashPaymentMethodId && amount"
          :currency="getCurrency(currencyId)"
          changeCalculator
          :total="amount"
          class="mb-4"
        />
        <span v-if="!transactionId">{{$t('accounts.top_up_warning')}}</span>
        <!-- ERROR -->
        <v-card-actions v-if="errors.paymentError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="error" class="mr-2">mdi-alert-circle-outline</v-icon>{{$t('error.generic_retry')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text color="primary" @click="postTransaction()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-card-actions v-if="errors.getTicketError || errors.printTicketError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="warning" class="mr-2">mdi-alert-outline</v-icon>{{$t('ticket_order.error.receipt')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text v-if="errors.getTicketError" color="primary" @click="getAndPintTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
              <v-btn text v-if="errors.printTicketError" color="primary" @click="printTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-layout v-if="loading.complete || loading.getTicket || loading.printTicket" align-center justify-center class="my-4 grey--text body">
          <i>{{
            loading.complete
              ? $t('package_order.loading.complete')
              : loading.getTicket
                ? $t('package_order.loading.ticket')
                : $t('package_order.loading.print')
          }}</i><loading-dots />
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-layout v-if="!transactionId" :column="xsOnly">
          <v-btn color="primary" dark text @click="open = false" :disabled="loading.complete">
            {{$t('actions.cancel_payment')}}
          </v-btn>
          <v-btn color="primary" text :disabled="!amount || loading.complete" :dark="!!amount" @click="postTransaction">
            {{$t('accounts.actions.confirm_completed_payment')}}
          </v-btn>
        </v-layout>
        <v-layout v-else justify-end>
          <v-btn v-if="transactionId" @click="open = false" dark outlined color="primary">{{$t('actions.close')}}</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moneyFormat from '@/mixins/MoneyFormat'
import vuetifyMixins from '@/mixins/Vuetify'
import { transactionTypes } from '@/config'
import { mapGetters } from 'vuex'
import { TransactionRequests } from '@/services/transaction.requests'
import printerMixins from '@/mixins/PrinterPeripheralActions'
import peripheralMixins from '@/mixins/PeripheralSocket'
const transactionService = new TransactionRequests()
export default {
  props: {
    triggerOpen: Date,
    guest: Object
  },
  data () {
    return {
      open: false,
      amount: null,
      currencyId: null,
      transactionId: null,
      paymentMethodId: null,
      errors: {
        paymentError: false,
        getTicketError: false,
        printTicketError: false
      },
      loading: {
        complete: false,
        getTicket: false,
        printTicket: false
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'configuration/currencies',
      getCurrency: 'configuration/getCurrency',
      localCurrency: 'configuration/localCurrency',
      foreignCurrency: 'configuration/foreignCurrency',
      cashPaymentMethodId: 'configuration/cashPaymentMethodId',
      foreignCurrencyExchangeRate: 'configuration/foreignCurrencyExchangeRate'
    })
  },
  methods: {
    postTransaction () {
      this.errors.paymentError = false
      this.loading.complete = true
      const isForeignCurrency = this.currencyId === this.foreignCurrency.id
      const transaction = {
        amount: isForeignCurrency ? this.amount * this.foreignCurrencyExchangeRate : this.amount,
        currencyId: this.localCurrency.id,
        type: transactionTypes.PAYMENT,
        paymentMethodId: this.paymentMethodId,
        negativeTransaction: this.amount < 0,
        guestId: this.guest.id,
        ...isForeignCurrency && {
          exchangeValue: this.foreignCurrencyExchangeRate,
          exchangeAmount: this.amount,
          exchangeCurrencyId: this.currencyId
        }
      }
      this.$store.dispatch('transactions/post', [transaction]).then((ids) => {
        this.transactionId = ids[0]
        this.getAndPintTicket()
        this.$store.commit('state/setMessage', { text: this.$t('transactions.success.add_balance'), color: 'success' })
        this.$store.dispatch('guests/get', { id: this.guest.id, save: true, decorate: true })
      }).catch((error) => {
        this.errors.paymentError = true
      }).finally(() => {
        this.loading.complete = false
      })
    },
    getAndPintTicket () {
      this.loading.getTicket = true
      this.errors.ticketGenerationError = false
      transactionService.getTicket(this.transactionId).then((base64Pdf) => {
        this.base64Ticket = base64Pdf
        this.printTicket()
      }).catch(() => {
        this.errors.ticketGenerationError = true
      }).finally(() => {
        this.loading.getTicket = false
      })
    },
    printTicket () {
      this.loading.printTicket = true
      this.errors.printTicketError = false
      this.sendPrinterAction(this.base64Ticket, 'application/pdf', () => {
        if (this.paymentMethodId !== this.cashPaymentMethodId) {
          this.open = false
        }
        this.loading.printTicket = false
      }, () => {
        this.errors.printTicketError = true
        this.loading.printTicket = false
      })
    }
  },
  watch: {
    triggerOpen () {
      this.open = true
    },
    open () {
      if (!this.open) {
        setTimeout(() => {
          this.paymentMethodId = null
          this.amount = null
          this.transactionId = null
          this.base64Pdf = null
          this.currencyId = null
          this.errors = {
            paymentError: false,
            getTicketError: false,
            printTicketError: false
          },
          this.loading = {
            complete: false,
            getTicket: false,
            printTicket: false
          }
          this.$store.commit('transactions/resetEdited')
        }, 300)
      }
    }
  },
  components: {
    PaymentMethodToggle: () => import('@/components/view-components/PaymentMethodToggle.vue'),
    LoadingDots: () => import('@/components/app-components/LoadingDots.vue'),
    PriceInput: () => import('@/components/view-components/PriceInput.vue')
  },
  mixins: [
    vuetifyMixins,
    moneyFormat,
    printerMixins,
    peripheralMixins
  ]
}
</script>